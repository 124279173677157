import React, {
  createContext,
  useEffect,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { setScrollbarWidth } from './screens';

const BackfireContext = createContext<
  | {
      fixed: boolean;
      setFixed: Dispatch<SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

export const BackfireProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [fixed, setFixed] = useState<boolean>(false);

  const scrollY = React.useRef<number | null>(null);
  useEffect(() => {
    if (fixed && scrollY.current === null) {
      scrollY.current = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY.current}px`;
    } else if (!fixed && scrollY.current !== null) {
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, scrollY.current);
      scrollY.current = null;

      setScrollbarWidth();
    }
  }, [fixed]);

  return (
    <BackfireContext.Provider value={{ fixed, setFixed }}>
      {children}
    </BackfireContext.Provider>
  );
};

export const useBackfire: () => {
  fix: (to: boolean) => void;
} = () => {
  const backfireContext = React.useContext(BackfireContext);
  if (backfireContext == null) {
    throw Error('useModal must be used within ModalProvider');
  }
  const { setFixed } = backfireContext;

  const fix: (to: boolean) => void = (to) => {
    setFixed(to);
  };

  return { fix };
};
