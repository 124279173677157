import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import { useScreen } from '../../../utils/screens';
import * as styles from './LogoCover.module.scss';

export const LogoCover: React.FC<{
  onAnimationComplete: () => void;
}> = ({ onAnimationComplete }) => {
  const { underMd } = useScreen();
  const playerWidth = underMd ? '200px' : '300px';

  return (
    <div className={styles.cover}>
      <Player
        style={{
          width: playerWidth,
          height: 'auto',
        }}
        src="/andwe_logo.json"
        onEvent={(event) => {
          if (event === 'complete') {
            onAnimationComplete();
          }
        }}
        autoplay
      />
    </div>
  );
};
