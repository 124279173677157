import React, { createContext, useEffect, useState } from 'react';
import * as variables from '../styles/variables.module.scss';

export type Theme = 'light' | 'dark';

const ThemeContext = createContext<
  | {
      current: Theme;
      setCurrent: (theme: Theme) => void;
    }
  | undefined
>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [current, setCurrent] = useState<Theme>('light');

  return (
    <ThemeContext.Provider value={{ current, setCurrent }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme: () => {
  current: Theme;
  switchTo: (theme: Theme) => void;
} = () => {
  const themeContext = React.useContext(ThemeContext);
  if (themeContext == null) {
    throw Error('useTheme must be used within ThemeProvider');
  }
  const { current, setCurrent } = themeContext;

  const switchDark: () => void = () => {
    document.documentElement.style.setProperty(
      '--color-main',
      variables.lightenbase,
    );
    document.documentElement.style.setProperty('--color-base', variables.black);
    document.documentElement.style.setProperty(
      '--color-base-accent',
      variables.darkenblack,
    );
  };

  const switchLight: () => void = () => {
    document.documentElement.style.setProperty('--color-main', variables.black);

    document.documentElement.style.setProperty(
      '--color-base',
      variables.lightenbase,
    );
    document.documentElement.style.setProperty(
      '--color-base-accent',
      variables.white,
    );
  };

  const switchTo: (theme: Theme) => void = (theme) => {
    setCurrent(theme);
  };

  useEffect(() => {
    switch (current) {
      case 'light':
        switchLight();
        break;
      case 'dark':
        switchDark();
        break;
    }

    return () => {
      switchLight();
    };
  }, [current]);

  return { current, switchTo };
};
