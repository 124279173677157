// extracted by mini-css-extract-plugin
export var accent = "#f36100";
export var black = "#2d2d2d";
export var darkenbase = "#ccc3b4";
export var darkenblack = "#000";
export var gray = "#a59d91";
export var headerAchievementHeightM = "120px";
export var headerHeightM = "124px";
export var headerHeightS = "60px";
export var lg = "1024";
export var lightblue = "#1e84e9";
export var lightenbase = "#f9f6f1";
export var lightenblack = "#484848";
export var lighterblue = "#e6f3f1";
export var md = "768";
export var middlebase = "#efebe4";
export var middleblack = "#333";
export var red = "red";
export var sm = "640";
export var white = "#fff";
export var xl = "1280";