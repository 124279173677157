import { GatsbyBrowser, Script } from 'gatsby';
import * as React from 'react';
import { LogoCover } from './src/components/page/top/LogoCover';
import './src/styles/global.scss';
import { BackfireProvider } from './src/utils/modal';
import { setScrollbarWidth, useScreen } from './src/utils/screens';
import { ThemeProvider } from './src/utils/theme';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({
  location,
  _,
}) => {
  const recaptchaVisibility = location.pathname.startsWith('/contact')
    ? 'visible'
    : 'hidden';
  document.documentElement.style.setProperty(
    '--recaptcha-visibility',
    recaptchaVisibility,
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  props,
  element,
}) => {
  return (
    <ShowScreenCover path={props.location.pathname}>{element}</ShowScreenCover>
  );
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  props,
  element,
}) => {
  return (
    <>
      <Script
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}
      />
      <ThemeProvider>
        <BackfireProvider>
          <ScrollBarHandler>{element}</ScrollBarHandler>
        </BackfireProvider>
      </ThemeProvider>
    </>
  );
};

const ShowScreenCover: React.FC<{
  path: string;
  children: React.ReactNode;
}> = ({ path, children }) => {
  const [logoAnimationShown, setLogoAnimationShown] = React.useState(false);
  const isRoot = path === '/';

  const showAnimation = isRoot && !logoAnimationShown;

  React.useEffect(() => {
    setScrollbarWidth();
  }, [showAnimation]);

  return (
    <>
      {showAnimation ? (
        <LogoCover onAnimationComplete={() => setLogoAnimationShown(true)} />
      ) : (
        <>{children}</>
      )}
    </>
  );
};

const ScrollBarHandler: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { underMd } = useScreen();
  const setPx = underMd ? 0 : undefined;

  React.useEffect(() => {
    setScrollbarWidth(setPx);
    window.addEventListener('resize', () => setScrollbarWidth(setPx));
    window.addEventListener('load', () => setScrollbarWidth(setPx));

    return () => {
      window.removeEventListener('resize', () => setScrollbarWidth(setPx));
      window.removeEventListener('load', () => setScrollbarWidth(setPx));
    };
  }, []);

  return <>{children}</>;
};
