import { useEffect, useRef, useState } from 'react';
import * as variables from '../styles/variables.module.scss';

type MediaType =
  | Exclude<keyof typeof variables, '__esModule'>
  | 'xxl'
  | 'unknown';

export const useScreen: () => {
  screen: MediaType;
  underMd: boolean;
  underLg: boolean;
  underXl: boolean;
  overSm: boolean;
  overMd: boolean;
  overLg: boolean;
} = () => {
  const [screen, setScreen] = useState<MediaType>('xxl');

  const listenWindowResize: () => void = () => {
    if (window.innerWidth > Number(variables.xl)) setScreen('xxl');
    else if (window.innerWidth > Number(variables.lg)) setScreen('xl');
    else if (window.innerWidth > Number(variables.md)) setScreen('lg');
    else if (window.innerWidth > Number(variables.sm)) setScreen('md');
    else setScreen('sm');
  };

  useEffect(() => {
    listenWindowResize();
    window.addEventListener('load', listenWindowResize);
    window.addEventListener('resize', listenWindowResize);
    return () => {
      window.removeEventListener('load', listenWindowResize);
      window.removeEventListener('resize', listenWindowResize);
    };
  }, []);

  const underMd = screen === 'sm' || screen === 'md';
  const underLg = underMd || screen === 'lg';
  const underXl = underLg || screen === 'xl';
  const overSm = !underMd;
  const overMd = !underLg;
  const overLg = !underXl;

  return { screen, underMd, underLg, underXl, overSm, overMd, overLg };
};

export const setScrollbarWidth: (px?: number) => void = (px) => {
  const scrollbarWidth =
    px !== undefined ? px : window.innerWidth - document.body.clientWidth;
  document.documentElement.style.setProperty(
    '--scrollbar-width',
    `${scrollbarWidth}px`,
  );
};

export const scrollIntoViewUnderHeader: (
  element: HTMLElement,
  adjustPx?: number,
) => void = (element, adjustPx = 0) => {
  window.scrollTo({
    top:
      window.scrollY +
      element.getBoundingClientRect().top -
      Number(variables.headerHeightM.replace('px', '')) +
      adjustPx,
    behavior: 'smooth',
  });
};

export const useShown: () => {
  ref: React.MutableRefObject<HTMLElement | null>;
  shown: boolean;
  overScreen: boolean;
  underScreen: boolean;
} = () => {
  const ref = useRef<HTMLElement | null>(null);
  const [shown, setShown] = useState<boolean>(false);
  const [overScreen, setOverScreen] = useState<boolean>(false);
  const [underScreen, setUnderScreen] = useState<boolean>(false);

  const judgeShown: (elem: HTMLElement) => boolean = (elem) => {
    const screenTopY = window.scrollY;
    const screenBottomY = window.scrollY + window.innerHeight;
    const elementTopY = elem.offsetTop;
    const elementBottomY = elem.offsetTop + elem.scrollHeight;
    return screenTopY < elementBottomY && screenBottomY > elementTopY;
  };

  const judgeOverScreen: (elem: HTMLElement) => boolean = (elem) => {
    const rect = elem.getBoundingClientRect();
    return rect.top < rect.height * -1;
  };

  const judgeUnderScreen: (elem: HTMLElement) => boolean = (elem) => {
    const rect = elem.getBoundingClientRect();
    return rect.top > window.innerHeight;
  };

  useEffect(() => {
    const handleElementMove: () => void = () => {
      if (ref.current === null) {
        return;
      }

      setShown(judgeShown(ref.current));
      setOverScreen(judgeOverScreen(ref.current));
      setUnderScreen(judgeUnderScreen(ref.current));
    };

    handleElementMove();
    window.addEventListener('load', handleElementMove);
    window.addEventListener('resize', handleElementMove);
    window.addEventListener('scroll', handleElementMove);

    return () => {
      window.removeEventListener('load', handleElementMove);
      window.removeEventListener('resize', handleElementMove);
      window.removeEventListener('scroll', handleElementMove);
    };
  }, [ref]);

  return { ref, shown, overScreen, underScreen };
};

export const useTouch: () => {
  refA: React.MutableRefObject<HTMLElement | null>;
  refB: React.MutableRefObject<HTMLElement | null>;
  touch: boolean;
  aOverB: boolean;
} = () => {
  const [aOverB, setAoverB] = useState<boolean>(false);
  const [touch, setTouch] = useState<boolean>(false);
  const refA = useRef<HTMLElement | null>(null);
  const refB = useRef<HTMLElement | null>(null);

  const judgeOver: (elemA: HTMLElement, elemnB: HTMLElement) => boolean = (
    elemA,
    elemB,
  ) => {
    const rectA = elemA.getBoundingClientRect();
    const rectB = elemB.getBoundingClientRect();
    return rectA.bottom < rectB.top;
  };

  const judgeTouch: (elemA: HTMLElement, elemnB: HTMLElement) => boolean = (
    elemA,
    elemB,
  ) => {
    const rectA = elemA.getBoundingClientRect();
    const rectB = elemB.getBoundingClientRect();
    const judgeX = rectA.left < rectB.right && rectB.left < rectA.right;
    const judgeY = rectA.top < rectB.bottom && rectB.top < rectA.bottom;

    return judgeX && judgeY;
  };

  useEffect(() => {
    const handleElementMove: () => void = () => {
      if (refA.current === null || refB.current === null) {
        return;
      }

      setAoverB(judgeOver(refA.current, refB.current));
      setTouch(judgeTouch(refA.current, refB.current));
    };

    handleElementMove();
    window.addEventListener('load', handleElementMove);
    window.addEventListener('resize', handleElementMove);
    window.addEventListener('scroll', handleElementMove);

    return () => {
      window.removeEventListener('load', handleElementMove);
      window.removeEventListener('resize', handleElementMove);
      window.removeEventListener('scroll', handleElementMove);
    };
  }, [refA, refB]);

  return { refA, refB, touch, aOverB };
};
